export function dateFormater(date?: string | Date) {
    if (!date) return;
    const formater = new Intl.DateTimeFormat('pt-BR', { 
        day: 'numeric', 
        month: 'numeric', 
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    })
    if (typeof date === 'string') {
        return formater.format(new Date(date));
    }
    return formater.format(date);
}