export const TOKEN_KEY = "hapon-admin-auth";
export const USER_DATA = "hapon-admin-user-data";
export const ADMIN_BFF_AZKV = "___ADMIN_BFF_URL___";
export const BFF_KEY_AZKV = "___BFF_KEY___";
export const ADMIN_BFF_DEV = "https://api-dev.hapvidalabs.net/hapon/admin";
export const BFF_KEY_DEV = "4193c63d18a34b988c05de7e6685e6bb";
export const ADMIN_BFF_HML = "https://api-hml.hapvidalabs.net/hapon/admin";
export const BFF_KEY_HML = "a5b7b185472d4949b2483ebfb285c8e8";
export const ADMIN_BFF_PRD = "https://api.hapvida.com.br/hapon/admin";
export const BFF_KEY_PRD = "dfb048764d284ae0aa88b927e65db8b7";
export const ADMIN_BFF_URL = ADMIN_BFF_AZKV;
export const BFF_KEY = BFF_KEY_AZKV;