import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  TagField,
  ShowButton,
  Card,
  Form,
  Select,
  Button,
  Icons,
  useSelect,
} from "@pankod/refine-antd";
import { IContents } from "interfaces";
import { CacheNotificationCard } from "components/cards/cache-notification-card";

export const ContentsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter ,searchFormProps } = useTable<IContents>({
    initialSorter: [
      {
        field: "key",
        order: "asc",
      },
    ],
    onSearch(data: any) {
        return [
          {
            field: 'view',
            operator: 'eq',
            value: data?.view
          },
          {
            field: 'key',
            operator: 'eq',
            value: data?.key
          },
        ]
    },
  });
  const { selectProps: viewSelectProps, queryResult: viewQueryResult } = useSelect<any>({
    resource: "v1/cms/views",
    hasPagination: false
  });

  const { selectProps: keySelectProps, queryResult: keyQueryResult } = useSelect<any>({
    resource: "v1/cms/keys/",
    hasPagination: false,
  });
  
  return (
    <>
    <CacheNotificationCard />
    <Card title="Busca">
        <Form {...searchFormProps} layout="inline">
          <Space>
            <Form.Item name="view" label="Filtrar Pagina:">
              <Select {...viewSelectProps} style={{ width: "200px" }} options={[{ label: 'Todas', value: '' },...(viewQueryResult.data?.data.map(i => ({ label: i, value: i })) ?? [{ label: undefined, value: undefined }])]} />
            </Form.Item>
            <Form.Item name="key" label="Filtrar Chave:">
              <Select {...keySelectProps} options={[{ label: 'Todas', value: '' },...(keyQueryResult.data?.data.map(i => ({ label: i, value: i })) ?? [{ label: undefined, value: undefined }])]} style={{ width: "200px" }} />
            </Form.Item>
            <Button
              size="small"
              type="primary"
              icon={<Icons.SearchOutlined />}
              onClick={searchFormProps.form?.submit}
            >
              Buscar
            </Button>
          </Space>
        </Form>
      </Card>
    <List>
      <Table {...tableProps} rowKey="_id" style={{ tableLayout: 'fixed', width: '100%' }}>
        <Table.Column
          dataIndex="view"
          key="view"
          title="Pagina"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("view", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="key"
          key="key"
          title="Chave"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("key", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="value"
          key="value"
          title="Valor"
          render={(value) => <TagField value={value} style={{ wordBreak: 'break-all', textWrap: 'wrap', maxWidth: '100%' }} />}
          defaultSortOrder={getDefaultSortOrder("value", sorter)}
          sorter
        />

        <Table.Column<IContents>
          title="Ações"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record._id} />
              <ShowButton hideText size="small" recordItemId={record._id} />
              <DeleteButton hideText size="small" recordItemId={record._id} />
            </Space>
          )}
        />
      </Table>
    </List>
    </>
  );
};
