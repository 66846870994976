import { HttpError, IResourceComponentsProps, usePermissions } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Card,
  Space,
  ShowButton,
  Form,
  Input,
  Button,
  Icons,
  Select,
} from "@pankod/refine-antd";
import { ConsumerLogs } from "interfaces";
import { dateFormater } from "utils/date-formater";

// A FAZER: tipagem do parametro do options extraida manualmente pois a lib não exporta.
type SelectOptionsType =  NonNullable<React.ComponentProps<typeof Select>['options']>;

const statusOptions: SelectOptionsType = [
  {
    label: 'Todos',
    value: '',
  },
  { 
    label: 'Enviado',
    value: 'enviado',
  },
  {
    label: 'Finalizado',
    value: 'finalizado',
  }
]

export const ProcessingLogList: React.FC<IResourceComponentsProps> = () => {

  const permissions = usePermissions();
  const basicAccess = permissions.data === "REPORTS_BASIC";

  const { tableProps, searchFormProps, sorter } = useTable<ConsumerLogs, HttpError>({
    onSearch: (values: any) => {
      return [
        {
          field: "consumer",
          operator: "eq",
          value: values.consumer,
        },
        {
          field: "status",
          operator: "eq",
          value: values.status,
        },
        {
          field: "execution_date",
          operator: "eq",
          value: values.execution_date,
        },
      ];
    },
    initialCurrent: 1,
    initialPageSize: 10,
    hasPagination: true,
  });

  return (
    <List>
      <Card title="Busca">
        <Form {...searchFormProps} layout="inline">
          <Space>
            <Form.Item name="consumer" label="Consumidor:">
              <Input className="input-custom" />
            </Form.Item>
            <Form.Item name="status" label="Status:">
              <Select style={{ width: '100px' }} options={statusOptions} className="input-custom" />
            </Form.Item>
            <Form.Item name="execution_date" label="Data de Execução:">
              <input type="date" className="input-custom" />
            </Form.Item>
            <Button
              size="small"
              type="primary"
              icon={<Icons.SearchOutlined />}
              htmlType="submit"
            >
              Buscar
            </Button>
          </Space>
        </Form>
      </Card>
      <Table {...tableProps} rowKey="_id">
        <Table.Column
          dataIndex="process_id"
          key="process_id"
          title="Id do Processo"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("process_id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="process"
          key="process"
          title="Nome do Processo"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("process", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="start_time"
          key="start_time"
          title="Data de Inicio"
          render={(value) => <TextField value={dateFormater(value)} />}
          defaultSortOrder={getDefaultSortOrder("start_time", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="end_time"
          key="end_time"
          title="Data de Fim"
          render={(value) => <TextField value={dateFormater(value)} />}
          defaultSortOrder={getDefaultSortOrder("end_time", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="last_status"
          key="last_status"
          title="Ultimo Status"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("last_status", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="statistics"
          key="statistics"
          title="Estatisticas"
          render={(value) => <pre style={{ display: 'flex', maxHeight: '200px', overflowY: 'auto' }}>{JSON.stringify(value, null, 2)}</pre>}
          defaultSortOrder={getDefaultSortOrder("statistics", sorter)}
          sorter
        />
        <Table.Column<ConsumerLogs>
          title="Ações"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <ShowButton
                hidden={basicAccess}
                hideText
                size="small"
                recordItemId={record._id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
