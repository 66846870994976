import { IResourceComponentsProps, useApiUrl, useNotification } from "@pankod/refine-core";
import {
  List,
  Button,
  Card,
  Icons,
  Select,
  useSelect,
} from "@pankod/refine-antd";
import { BFF_KEY } from "environment";
import { useState } from "react";
import { IHealthInsurer } from "interfaces";

export const ControlPanel: React.FC<IResourceComponentsProps> = () => {
  const apiUrl = useApiUrl();
  const toast = useNotification();
  const [operatorId, setOperatorId] = useState<number | null>(null);

  const { selectProps } = useSelect<IHealthInsurer>({
    resource: "v1/health-insurer",
    optionLabel: "Description",
    optionValue: "HealthInsurerId",
    hasPagination: true,
  });

  async function handleReprocessPlanTable() {
    let url = `${apiUrl}/v1/consumers/plans`;
    if (operatorId !== null) url = `${apiUrl}/v1/consumers/plans-by-hi/${operatorId}`;
    await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        'ocp-apim-subscription-key': BFF_KEY,
      },
    }).then(async (res) => {
      const data = await res.json();
      toast.open?.({
        message: data.message,
        type: 'success',
      })
    }).catch(() => {
      toast.open?.({
        message: 'Ocorreu um erro ao tentar reprocessar os planos, tente novamente mais tarde.',
        type: 'error',
      })
    })
  }

  function handleChangeOperator(option: any) {
    if (typeof option === 'number') {
      setOperatorId(option);
    } else {
      setOperatorId(null);
    }
  }

  return (
    <List>
      <Card title="Ações">
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Select {...selectProps} defaultActiveFirstOption options={[{ label: 'Todas', value: '' },...selectProps.options!]} style={{ width: '100px', }} onChange={handleChangeOperator} />
          <Button
            size="large"
            icon={<Icons.UpCircleOutlined />}
            onClick={handleReprocessPlanTable}
          >
            Reprocessar Planos
          </Button>
        </div>
      </Card>
    </List>
  );
};
