import { HttpError, IResourceComponentsProps, useExport, usePermissions } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Card,
  Space,
  ShowButton,
  Form,
  Input,
  Button,
  Icons,
  ExportButton
} from "@pankod/refine-antd";
import { ILeads } from "interfaces";
import { IMaskInput } from "react-imask";
import { useState } from "react";

interface ISearch {
  cpf: string,
  ctrl_number: string,
  cell_phone: string,
  start_dt: string,
  end_dt: string
};

export const BudgetsList: React.FC<IResourceComponentsProps> = () => {

  const permissions = usePermissions();
  const basicAccess = permissions.data === "REPORTS_BASIC";

  const [params, setParams] = useState<ISearch>();

  const now = new Date();

  const { tableProps, searchFormProps, sorter } = useTable<ILeads, HttpError>({
    initialFilter: [
      {
        field: "start_dt",
        operator: "eq",
        value: now,
      },
      {
        field: "end_dt",
        operator: "eq",
        value: now,
      },
    ],
    onSearch: (values: any) => {
      return [
        {
          field: "cpf",
          operator: "eq",
          value: values.cpf,
        },
        {
          field: "ctrl_number",
          operator: "eq",
          value: values.ctrl_number,
        },
        {
          field: "cell_phone",
          operator: "eq",
          value: values.cell_phone,
        },
        {
          field: "start_dt",
          operator: "eq",
          value: values.start_dt ? values.start_dt : now,
        },
        {
          field: "end_dt",
          operator: "eq",
          value: values.end_dt ? values.end_dt : now,
        }
      ];
    },
    initialCurrent: 1,
    initialPageSize: 100,
    hasPagination: true,
  });

  const { triggerExport, isLoading: exportLoading } = useExport<ILeads>({
    filters: [
      {
        field: "cpf",
        operator: "eq",
        value: params?.cpf ?? "",
      },
      {
        field: "ctrl_number",
        operator: "eq",
        value: params?.ctrl_number ?? "",
      },
      {
        field: "cell_phone",
        operator: "eq",
        value: params?.cell_phone ?? "",
      },
      {
        field: "start_dt",
        operator: "eq",
        value: params?.start_dt ?? "",
      },
      {
        field: "end_dt",
        operator: "eq",
        value: params?.end_dt ?? "",
      },
    ],
    mapData: (item) => {
      return {
        data: item.created_at,
        nome: item.name,
        cpf: item.cpf,
        telefone: item.cell_phone,
        email: item.email,
        operadora: item.health_insurer,
        cidade: item.city,
        uf: item.state,
        nu_controle: item.control_number,
        produto: item.product,
        num_vidas: item.dependents,
        ultima_tela: item.last_screen,
        origem: item.origin
      };
    },
    pageSize: 100
  });

  return (
    <List
      headerProps={{
        extra: (
          <Space>
            <ExportButton
              onClick={triggerExport}
              loading={exportLoading}
            />
          </Space>
        ),
      }}
    >
      <Card title="Busca">
        <Form {...searchFormProps} layout="inline">
          <Space>
            <Form.Item name="cpf" label="Cpf">
              <IMaskInput mask={"000.000.000-00"} className="input-custom" />
            </Form.Item>
            <Form.Item name="ctrl_number" label="# Controle:">
              <Input className="input-custom" />
            </Form.Item>
            <Form.Item name="cell_phone" label="Telefone:">
              <IMaskInput mask="(00)00000-0000" className="input-custom" />
            </Form.Item>
            <Form.Item name="start_dt" label="Data de Início:">
              <input type="Date" className="input-custom" />
            </Form.Item>
            <Form.Item name="end_dt" label="Data Fim:">
              <input type="Date" className="input-custom" />
            </Form.Item>
            <Button
              size="small"
              type="primary"
              icon={<Icons.SearchOutlined />}
              onClick={() => {
                setParams({
                  cpf: searchFormProps.form?.getFieldValue("cpf"),
                  ctrl_number: searchFormProps.form?.getFieldValue("ctrl_number"),
                  cell_phone: searchFormProps.form?.getFieldValue("cell_phone"),
                  start_dt: searchFormProps.form?.getFieldValue("start_dt"),
                  end_dt: searchFormProps.form?.getFieldValue("end_dt")
                });
                searchFormProps.form?.submit();
              }}
            >
              Buscar
            </Button>
          </Space>
        </Form>
      </Card>
      <Table {...tableProps} rowKey="_id">
        <Table.Column
          dataIndex="name"
          key="name"
          title="Nome"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("Name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="cpf"
          key="cpf"
          title="Cpf"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("cpf", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="cell_phone"
          key="cell_phone"
          title="Telefone"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("cell_phone", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="email"
          key="email"
          title="E-Mail"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("email", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="health_insurer"
          key="health_insurer"
          title="Operadora"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("health_insurer", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="city"
          key="city"
          title="Cidade"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("city", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="state"
          key="state"
          title="Estado"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("state", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="control_number"
          key="control_number"
          title="Número de Controle"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("control_number", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="product"
          key="product"
          title="Produto"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("product", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="dependents"
          key="dependents"
          title="Vidas"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("dependents", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="last_screen"
          key="last_screen"
          title="Última Tela"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("last_screen", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="origin"
          key="origin"
          title="Origem"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("origin", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="created_at"
          key="created_at"
          title="Data de Criação"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        />
        <Table.Column<ILeads>
          title="Ações"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <ShowButton
                hidden={basicAccess}
                hideText
                size="small"
                recordItemId={record._id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
