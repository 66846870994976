import { Row, Col, Card } from "@pankod/refine-antd";
import { useEffect } from "react";
import {
    TotalRegisteredTodayBudget,
    TotalFinishedTodayBudget,
    TotalTouchedLives,
    TotalMonthlyBudget,
    ChartBudgetCount,
    Top10HelathInsurer,
    Top10BranchOffices
} from "components/dashboard";

export const HomePage: React.FC = () => {

    useEffect(() => {
        const reloadCount = (sessionStorage.getItem('reloadCount') as unknown) as number;
        if (reloadCount < 1) {
            sessionStorage.setItem('reloadCount', String(reloadCount + 1));
            window.location.reload();
        } else {
            sessionStorage.removeItem('reloadCount');
        }
    }, []);

    return (
        <>
            <Row gutter={20}>
                <Col span={6}>
                    <Card
                        title="Total de orçamentos cadastrados hoje"
                        style={{ borderRadius: "15px", height: "200px" }}
                        headStyle={{ textAlign: "center" }}
                    >
                        <p style={{ textAlign: "center", fontSize: 80, fontWeight: 700, color: "#0054b8" }}>
                            <TotalRegisteredTodayBudget />
                        </p>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card
                        title="Total de orçamentos finalizados hoje"
                        style={{ borderRadius: "15px", height: "200px" }}
                        headStyle={{ textAlign: "center" }}
                    >
                        <p style={{ textAlign: "center", fontSize: 80, fontWeight: 700, color: "#0054b8" }}>
                            <TotalFinishedTodayBudget />
                        </p>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card
                        title="Total de vidas incorporadas no mês"
                        style={{ borderRadius: "15px", height: "200px" }}
                        headStyle={{ textAlign: "center" }}
                    >
                        <p style={{ textAlign: "center", fontSize: 80, fontWeight: 700, color: "#0054b8" }}>
                            <TotalTouchedLives />
                        </p>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card
                        title="Total de orçamentos cadastrados no mês"
                        style={{ borderRadius: "15px", height: "200px" }}
                        headStyle={{ textAlign: "center" }}
                    >
                        <p style={{ textAlign: "center", fontSize: 80, fontWeight: 700, color: "#0054b8" }}>
                            <TotalMonthlyBudget />
                        </p>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row gutter={20}>
                <Col span={12}>
                    <Card
                        title="Orçamentos cadastrados nos últimos 7 dias"
                        style={{ borderRadius: "15px" }}
                        headStyle={{ textAlign: "center" }}
                    >
                        <ChartBudgetCount />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card
                        title="Top 10 Operadoras que mais vendem"
                        style={{ borderRadius: "15px" }}
                        headStyle={{ textAlign: "center" }}
                    >
                        <Top10HelathInsurer />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card
                        title="Top 10 Filiais que mais vendem"
                        style={{ borderRadius: "15px" }}
                        headStyle={{ textAlign: "center" }}
                    >
                        <Top10BranchOffices />
                    </Card>
                </Col>
            </Row>
        </>
    );
};