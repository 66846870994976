import { IResourceComponentsProps, useApiUrl, useNotification } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  DeleteButton,
  Form,
  Upload,
  getValueFromEvent,
  useForm,
  ImageField,
  Button,
  Icons,
} from "@pankod/refine-antd";
import { IFile } from "interfaces";
import { BFF_KEY } from "environment";

// A FAZER: tipagem do parametro do onChange extraida manualmente pois a lib não exporta
type UploadStateInfo =  Parameters<NonNullable<React.ComponentProps<typeof Upload.Dragger>['onChange']>>[0];

export const FileList: React.FC<IResourceComponentsProps> = () => {

  const { tableProps, tableQueryResult, sorter } = useTable<IFile>({
    initialSorter: [
      {
        field: "HealthInsurerId",
        order: "asc",
      },
    ],
  });

  const { formProps } = useForm<IFile>();

  const apiUrl = useApiUrl();

  const toast = useNotification();

  function handleChangeUploadStatus(info: UploadStateInfo) {
    switch (info.file.status) {
      case 'uploading':
        return;
      case 'error':
        toast.open?.({
          message: info.file.response?.error ?? 'ocorreu um erro ao fazer upload do arquivo, tente novamente mais tarde.',
          type: 'error',
        })
        break
      case 'done':
      case 'success':
        tableQueryResult.refetch();
    }
  }

  function handleCopyUrlToClipboard(record: IFile) {
    return () => {
      navigator.clipboard.writeText(record.file_url);
      toast.open?.({
        message: 'URL copiada para sua area de transferencia.',
        type: 'success',
      })
    }
  }

  return (
    <>
      <List canCreate={false}>
        <Form {...formProps} layout="vertical">
          <Form.Item label="Novos arquivos: ">
            <Form.Item
              name="image"
              valuePropName="fileList"
              getValueFromEvent={getValueFromEvent}
              noStyle
            >
              <Upload.Dragger
                name="file"
                action={`${apiUrl}/v1/cms-files/`}
                headers={{ "ocp-apim-subscription-key": BFF_KEY }}
                listType="picture"
                maxCount={1}
                onChange={handleChangeUploadStatus}
              >
                <p className="ant-upload-text">Arraste e solte os novos arquivos nesta área.</p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
        </Form>
        <Table 
          {...tableProps} 
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
          }}
          rowKey="file_name"
        >
          <Table.Column<IFile>
            title="Imagem"
            dataIndex="image"
            render={(_, record) => (
              <ImageField
                value={record.file_url}
                title={record.file_name}
                width={100}
              />
            )}
            width="120px"
          />
          <Table.Column
            dataIndex="file_name"
            key="file_name"
            title="Nome"
            render={(value) => <TextField strong value={value.replace("hapon/cms/", "")} />}
            defaultSortOrder={getDefaultSortOrder("file_name", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="file_url"
            key="file_url"
            title="Url"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("file_url", sorter)}
            sorter
          />
          <Table.Column<IFile>
            title="Ações"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <Button
                  size="small"
                  icon={<Icons.CopyOutlined />}
                  onClick={handleCopyUrlToClipboard(record)}
                >Copiar</Button>
                <DeleteButton hideText size="small" recordItemId={record.file_name.replace("hapon/cms/", "")} />
              </Space>
            )}
          />
        </Table>
      </List>
    </>
  );
};
