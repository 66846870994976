import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Show, Typography } from "@pankod/refine-antd";

import { IBranchOffices } from "interfaces";

const { Title, Text } = Typography;

export const BranchMunicipalitiesShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IBranchOffices>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading} canEdit={false}>
      <Title level={5}>Id área</Title>
      <Text>{record?.AreaId}</Text>

      <Title level={5}>Nome da área</Title>
      <Text>{record?.AreaDescription}</Text>

      <Title level={5}>Estado</Title>
      <Text>{record?.Uf}</Text>

      <Title level={5}>Id filial</Title>
      <Text>{record?.BranchOfficeId}</Text>
    </Show>
  );
};
