import {
    Button,
    Card,
    Spin,
    useTable,
    Table,
    TextField,
    TableProps,
} from "@pankod/refine-antd";
import { useApiUrl, useNotification } from "@pankod/refine-core";
import { BFF_KEY } from "environment";
import { ILinkedPlaces } from "interfaces";
import { useState } from "react";

type Props = {
    closeModal: () => void;
    refreshTable: () => void;
}

export const ModalBranchOfficeMunicipiesBindMutiple: React.FC<Props> = ({ closeModal, refreshTable }) => {
    const [selectedRows, setSelectedRows] = useState<ILinkedPlaces[]>([]);
    const { tableProps, tableQueryResult } = useTable<ILinkedPlaces>({
        resource: 'v1/branch-office-place/list-unlinked-places/',
        hasPagination: true,
    });
    const rowSelection: TableProps<ILinkedPlaces>['rowSelection'] = {
        type: 'checkbox',
        onChange: (selectedRowKeys: React.Key[], selectedRows: ILinkedPlaces[]) => {
          setSelectedRows(selectedRows);
        },
      };

    const toast = useNotification();
    const apiUrl = useApiUrl();
    const handleMutipleBindMunicipieToFilial = async () => {
        if (!selectedRows.length) {
            toast.open?.({
                message: 'selecione pelo menos 1 linha para vincular a filial ao municipio',
                type: 'error',
            });
            return;
        }
        await fetch(`${apiUrl}/v1/branch-office-place/multi-update-areaid/`, {
            method: 'PUT',
            headers: {
                'ocp-apim-subscription-key': BFF_KEY,
                "Content-type": 'application/json',
            },
            body: JSON.stringify(selectedRows.map((data) => {
                return {
                    Id: data.BranchOfficePlace.Id,
                    AreaId: data.BranchOffice.AreaId,
                }
            }))
        }).then(() => {
            toast.open?.({
                message: 'multi vinculações realizadas com sucesso.',
                type: 'success',
            });
        }).catch(() => {
            toast.open?.({
                message: 'ocorreu um erro ao tentar realizar multi vinculações com as linhas selecionadas, tente novamente mais tarde.',
                type: 'error',
            });
        }).finally(() => {
            refreshTable();
            tableQueryResult.refetch();
            closeModal();
        });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Button 
            type="primary" 
            style={{ alignSelf: 'end', marginBottom: '8px', width: 'fit-content' }}
            onClick={handleMutipleBindMunicipieToFilial}
            >
                Vincular Filiais Selecionadas
            </Button>
        <Card 
            title="Verifique e Selecione as filias para fazer o vinculo com municipio"
            style={{ overflowY: 'scroll', maxHeight: '70vh' }}
        >
            {tableQueryResult.isLoading 
                ? <Spin tip="Carregando Filiais" /> 
                : (
                    <Table
                        {...tableProps}
                        rowSelection={rowSelection}
                        rowKey={(data) => data.BranchOffice.AreaId}
                    >
                        <Table.Column
                            dataIndex="BranchOffice"
                            key="BranchOffice"
                            title="Id da Area"
                            render={(value) => <TextField value={value.AreaId} />}
                        />
                        <Table.Column 
                            dataIndex="BranchOffice"
                            key="BranchOffice"
                            title="Filial"
                            render={(value) => <TextField value={value.AreaDescription} />}
                        />
                        <Table.Column
                            dataIndex="BranchOffice"
                            key="BranchOffice"
                            title="Estado da Filial"
                            render={(value) => <TextField value={value?.Uf} />}
                        />
                        <Table.Column
                            dataIndex="BranchOfficePlace"
                            key="BranchOfficePlace"
                            title="Id"
                            render={(value) => <TextField value={value?.Id} />}
                        />
                        <Table.Column
                            dataIndex="BranchOfficePlace"
                            key="BranchOfficePlace"
                            title="Municipio"
                            render={(value) => <TextField value={value?.PlaceDescription} />}
                        />
                        <Table.Column
                            dataIndex="BranchOfficePlace"
                            key="BranchOfficePlace"
                            title="Estado do Municipio"
                            render={(value) => <TextField value={value?.Uf} />}
                        />
                    </Table>
                )
            }
        </Card>
        </div>
    );
}