import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Show, Typography } from "@pankod/refine-antd";

import { ConsumerLogs } from "interfaces";
import { dateFormater } from "utils/date-formater";

const { Title, Text } = Typography;

export const ProcessingLogShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<ConsumerLogs>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={4}>Id do Processo</Title>
      <Text>{record?.process_id}</Text>
      <Title level={4}>Nome do Processo</Title>
      <Text>{record?.process}</Text>
      <Title level={4}>Comando de Origem</Title>
      <Text>{record?.command_origin}</Text>
      <Title level={4}>Data de Inicio</Title>
      <Text>{dateFormater(record?.start_time)}</Text>
      <Title level={4}>Data de Fim</Title>
      <Text>{dateFormater(record?.end_time)}</Text>
      <Title level={4}>Ultimo Status</Title>
      <Text>{record?.last_status}</Text>
      <Title level={4}>Estatisticas</Title>
      <pre>{JSON.stringify(record?.statistics, null, 2)}</pre>
    </Show>
  );
};
