import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  useForm,
} from "@pankod/refine-antd";
import { IBranchOffices } from "interfaces";
import { BranchOfficesForm } from "components/forms";

export const BranchMunicipalitiesCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IBranchOffices>();
  return (
    <Create saveButtonProps={saveButtonProps}>
      <BranchOfficesForm
        formProps={formProps}
        queryResult={queryResult}
      />
    </Create>
  );
};
